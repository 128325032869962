import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { appConfig } from "../appConfig";
import iGiveLogo from '../assets/iGiveLogo.png';

function Home() {
    const currYear = new Date().getFullYear();
    const [bookingDiscCall, setBookingDiscCall] = React.useState(false);
    const [name, setName] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const handleDiscCall = () => {
        if (name.length && number.length) {
            setLoading(true);
            fetch("https://0rn0kuzkvj.execute-api.ap-southeast-2.amazonaws.com/prod/submitDiscoveryCall", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                name,
                number
            }),
            })
            .then((res) => res.json())
            .then((data) => {
                setBookingDiscCall(false);
                setLoading(false);
            });
        }
    }
    return(<>
    {loading && (
        <Box sx={{
            minHeight: '100vh',
            background: 'linear-gradient(45deg, #757575, #979797)'
        }}>
            <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%)' }}/>
        </Box>
    )}
    {bookingDiscCall && !loading && (
        <Box sx={{
            minHeight: '100vh',
            background: 'linear-gradient(45deg, #757575, #979797)'
        }}>
            <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Grid container sx={{ textAlign: 'center', paddingTop: 5 }}>
                    <Grid item xs={0} md={3} />
                    <Grid item xs={12} md={6}>
                        <Typography variant='h1' sx={{ color: appConfig.colors.foregroundDefault }}>Book Discovery Call</Typography>
                        <TextField fullWidth={true} sx={{ marginTop: 5 }} label='Your Name' placeholder={'Joe Smith'} value={name} onChange={(e) => setName(e.target.value)} />
                        <TextField fullWidth={true} sx={{ marginTop: 5 }} label='Your Number' placeholder={'0491 578 888'} value={number} onChange={(e) => setNumber(e.target.value)} />
                        <Button variant='contained' sx={{ marginTop: 5}} onClick={handleDiscCall}>Book Discovery Call</Button>
                    </Grid>
                    <Grid item xs={0} md={3} />
                </Grid>
            </Box>
            <Box sx={{ position: 'fixed', bottom: 0, textAlign: 'center', width: '100%', paddingY: 2, color: appConfig.colors.foregroundDefault }}>
                <Typography>
                    iGive.au - For creators & performers - With ❤️ from <a href='https://prudentpixel.com.au' target={'_blank'} rel='noreferrer' style={{ color: appConfig.colors.foregroundDefault}}>Prudent Pixel Australia</a> - © {currYear}
                </Typography>
            </Box>
        </Box>
    )}
    {!loading && !bookingDiscCall && (
        <Box sx={{
            minHeight: '100vh',
            background: 'linear-gradient(45deg, #757575, #979797)'
        }}>
            <Box sx={{ padding: 5}}>
                <img src={iGiveLogo} alt={'I Give Dot A U'} style={{ maxWidth: '100%' }} />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant='h1'>iGive.au</Typography>
                <Typography variant='h2'>Giving Simplified</Typography>
                <Typography variant='h4'>We're for creators/public speakers/public performers</Typography>
                <Typography variant='h4'>Sound Interesting?</Typography>
                <Button variant='contained' sx={{ marginTop: 5}} onClick={() => setBookingDiscCall(true)}>Book Discovery Call</Button>
            </Box>
            <Box sx={{ position: 'fixed', bottom: 0, textAlign: 'center', width: '100%', paddingY: 2, color: appConfig.colors.foregroundDefault }}>
                <Typography>
                    iGive.au - For creators & performers - With ❤️ from <a href='https://prudentpixel.com.au' target={'_blank'} rel='noreferrer' style={{ color: appConfig.colors.foregroundDefault}}>Prudent Pixel Australia</a> - © {currYear}
                </Typography>
            </Box>
        </Box>
    )}
    </>)
}

export default Home;