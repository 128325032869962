import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { createTheme } from '@mui/material/styles';
import { appConfig } from './appConfig';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';

const container = document.getElementById('root')!;
const root = createRoot(container);

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: appConfig.colors.goldColor,
          },
          "input": {
            color: appConfig.colors.foregroundDefault,
          }
        }
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: appConfig.colors.foregroundDefault,
        }
      }
    }
  },
  palette: {
    primary: {
      main: appConfig.colors.goldColor,
    }
  },
});
theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '6rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem'
  }
}

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
